import { useMutation, UseMutationResult } from 'react-query';

import { apiClient } from 'api/base';
import { ApiRoutes } from 'api/routes';
import { TAxiosRequestError } from 'api/types';
import { transformRoute } from 'utils/routing';
import { UserTypes } from 'types/user';

const useSendVerificationCode = (
  onSuccess?: () => void,
  onError?: (error: TAxiosRequestError) => void,
): UseMutationResult<void, TAxiosRequestError, string> =>
  useMutation(
    async (email: string) =>
      await apiClient.post(
        transformRoute(ApiRoutes.SEND_VERIFICATION_CODE, UserTypes.employee),
        { email },
      ),
    {
      onSuccess,
      onError,
    },
  );

export default useSendVerificationCode;
