import styled from 'styled-components';

import Button from 'components/Button';
import CustomAlert from 'components/CustomAlert';

export const StyledForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
});

export const StyledCustomAlert = styled(CustomAlert)({
  marginBottom: '32px',
});

export const PasswordInputWrapper = styled('div')({
  marginBottom: '20px',
});

export const ConfirmPasswordInputWrapper = styled('div')({
  marginBottom: '40px',
  '@media (max-width: 1024px)': {
    marginBottom: '60px',
  }
});

export const StyledButton = styled(Button)({
  width: '100%',
  marginBottom: '20px',
  '&.ant-btn-icon-only.ant-btn-lg': {
    width: '100%',
  },
  '@media (max-width: 1024px)': {
    marginBottom: 0,
  }
});
