export const QUERY_KEYS = {
  USER_PROFILE: 'userProfile',
  GET_USER_FROM_LOCAL_STORAGE: 'loadUserEmailFromLocalStorage',
  CHECK_RESET_IS_EXPIRED: 'checkResetIsExpired',
  DEALERS_LIST: 'dealersList',
  DEALERS_SELECT_LIST: 'dealersSelectList',
  CURRENCY_SERIES_SELECT_LIST: 'currencySeriesSelectList',
  DEALER_DETAILS: 'dealerDetails',
  CURRENCY_REQUESTS: 'currencyRequests',
  CURRENCIES_FOR_REQUEST: 'currenciesForRequest',
  ALL_CURRENCIES_FOR_REQUEST: 'allCurrenciesForRequest',
  REQUEST_DETAILS: 'requestDetails',
  REQUEST_INVOICE: 'requestInvoice',
  REQUESTS_PRE_COUNT: 'requestsPreCount',
  CHECK_EMAIL_FROM_REQUEST_WILL_BE_SENT: 'emailFromRequestWillBeSent',
  CERTIFICATES: 'certificates',
  RESOURCES: 'resources',
  DENOMINATIONS: 'denominations',
  SERIAL_NUMBERS: 'serialNumbers',
  SERIAL_NUMBER_DETAILS: 'serialNumberDetails',
  CHECK_SN_DELETE_AVAILABLE: 'serialNumberDeleteAvailable',
  SERIAL_NUMBERS_PRE_COUNT: 'serialNumbersPreCount',
  SUBSCRIPTIONS: 'subscriptions',
  AGGREGATED_SERIAL_NUMBERS: 'aggregatedSerialNumbers',
  CUSTOMER_INFO: 'customerInfo',
  CUSTOMER_BASE_INFO: 'customerBaseInfo',
  CUSTOMER_SNS_OR_SUBS_COUNT_BY_CURRENCY: 'customerSNsOrSubsCountByCurrency',
  CUSTOMERS_LIST: 'customersList',
  CUSTOMERS_SELECT_LIST: 'customersSelectList',
  CUSTOMERS_PRE_COUNT: 'customersPreCount',
  EMPLOYEES_LIST: 'employeesList',
  EMPLOYEE_DETAILS: 'employeeDetails',
  EMPLOYEES_PRE_COUNT: 'employeesPreCount',
  MACHINES_LIST: 'machinesList',
  DOWNLOADED_FILE: 'downloadedFile',
  SUBSCRIPTION_PLANS: 'subscriptionsPlans',
  PAYMENT_INVOICE: 'paymentInvoice',
  INVOICES_LIST: 'invoicesList',
  ANALYTICS_PRE_COUNT: 'analyticsPreCount',
  CURRENCY_ANALYTICS_PRE_COUNT: 'currencyAnalyticsPreCount',
  HOLDINGS_ANALYTICS_PRE_COUNT: 'holdingsAnalyticsPreCount',
  CURRENCY_ANALYTICS: 'currencyAnalytics',
  DEALERS_ANALYTICS: 'dealersAnalytics',
  STATES_ANALYTICS: 'statesAnalytics',
  CURRENCY_ANALYTICS_TOTAL: 'currencyAnalyticsTotal',
  STATES_ANALYTICS_TOTAL: 'statesAnalyticsTotal',
  TOTAL_ANALYTICS: 'totalAnalytics',
  SAVE_AND_SHARE: 'saveAndShare',
};
