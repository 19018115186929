import { AxiosError } from 'axios';
import { FC, useEffect, useState } from 'react';

import { useForgotPassword, IRequestErrorPayload } from 'api/auth';
import { EMPTY_STRING, INITIAL_TIMER_VALUE } from 'constants/common';
import { SERVER_ERROR_STRING } from 'constants/messages';
import { useFormik } from 'hooks';

import {
  FORGOT_PASSWORD_TEXT,
  INITIAL_FORGOT_PASSWORD_VALUES,
  RESEND_LINK_TIMER_DURATION_MS,
} from './constants';
import ForgotPassword from './ForgotPassword';
import { forgotPasswordValidationSchema } from './validation';
import { IForgotPasswordValues } from './types';

const ForgotPasswordContainer: FC = () => {
  const handleSubmit = (forgotPasswordPayload: IForgotPasswordValues) => {
    if (
      timer !== INITIAL_TIMER_VALUE &&
      forgotPasswordPayload.email === sentEmail
    ) {
      setErrorMessage(FORGOT_PASSWORD_TEXT.forgotPasswordAlert);
      return;
    }
    setErrorMessage(null);
    sendLinkMutate(forgotPasswordPayload);
  };

  const formik = useFormik({
    initialValues: INITIAL_FORGOT_PASSWORD_VALUES,
    validationSchema: forgotPasswordValidationSchema,
    onSubmit: handleSubmit,
  });

  const [timer, setTimer] = useState<number | ReturnType<typeof setTimeout>>(
    INITIAL_TIMER_VALUE,
  );
  const [isFormVisible, setFormVisible] = useState(true);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [sentEmail, setSentEmail] = useState<string>(EMPTY_STRING);

  const onBackLinkClick = () => {
    setSentEmail(formik.values.email);
    setFormVisible(true);
  };

  const onResendButtonClick = () => {
    handleSubmit(formik.values);
  };

  const setResendTimer = () => {
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        setTimer(INITIAL_TIMER_VALUE);
      }, RESEND_LINK_TIMER_DURATION_MS),
    );
  };

  const showResendView = () => {
    setFormVisible(false);
    setResendTimer();
    setErrorMessage(null);
    setSentEmail(EMPTY_STRING);
  };

  const handleError = (error: AxiosError<IRequestErrorPayload>) => {
    let errorResetLinkMessage =
      error?.response?.data?.message || error?.message;

    if (errorResetLinkMessage.includes(SERVER_ERROR_STRING)) {
      errorResetLinkMessage = SERVER_ERROR_STRING;
      setFormVisible(false);
      if (errorMessage) {
        setResendTimer();
      }
    }
    setErrorMessage(errorResetLinkMessage);
  };

  const { mutate: sendLinkMutate, isLoading: isSendLinkLoading } =
    useForgotPassword({
      onSuccess: showResendView,
      onError: handleError,
    });

  useEffect(() => {
    return () => {
      setErrorMessage(null);
      clearTimeout(timer);
    };
  }, [timer]);

  return (
    <ForgotPassword
      formik={formik}
      timer={timer}
      errorMessage={errorMessage}
      isFormVisible={isFormVisible}
      isSendLinkLoading={isSendLinkLoading}
      onBackLinkClick={onBackLinkClick}
      onResendButtonClick={onResendButtonClick}
    />
  );
};

export default ForgotPasswordContainer;
