import { TPhoneNumberCountryCode } from './phoneNumbers';

import { TUploadFile } from './files';

export enum UserTypes {
  employee = 'employee',
  customer = 'customer',
}

export enum UserRoles {
  super_admin = 'super_admin',
  admin = 'admin',
  manager = 'manager',
  vault = 'vault',
  sales = 'sales',
  customer_service = 'customer_service',
}
export interface IUserInfo {
  _id: string;
  firstName: string;
  lastName: string;
  role: UserRoles;
  email: string;
  phoneNumber: string;
  countryCode: TPhoneNumberCountryCode;
  isArchived: boolean;
  address?: string;
  avatar?: TUploadFile | null;
  machineSN?: string;
}

export type TEmployeeParams = { employeeId: string };
