import { FC } from 'react';
import { isEmpty } from 'lodash';

import {
  DefaultInput,
  MAX_EMAIL_LENGTH,
  PasswordInput,
} from 'components/Inputs';
import { APP_ROUTE } from 'constants/appRoutes';
import { ALERT_TYPE } from 'components/Alert';

import {
  StyledAlert,
  InputWrapper,
  ForgotRememberMeWrapper,
  StyledCheckbox,
  StyledLink,
  StyledButton,
} from './styled';
import { ISignInFormProps, SignInFormField } from './types';
import { SIGN_IN_FORM_TEXT } from './constants';

const SignInForm: FC<ISignInFormProps> = ({
  formik,
  isAuthenticateLoading,
  errorMessage,
}) => {
  const { values, errors, handleSubmit, getFieldProps } = formik;

  const isErrorAlertShown = errorMessage && isEmpty(errors);

  return (
    <form onSubmit={handleSubmit}>
      {isErrorAlertShown && (
        <StyledAlert type={ALERT_TYPE.ERROR} message={errorMessage} />
      )}
      <InputWrapper>
        <DefaultInput
          labelText={SIGN_IN_FORM_TEXT.EMAIL_LABEL}
          errorMessage={errors[SignInFormField.EMAIL]}
          maxLength={MAX_EMAIL_LENGTH}
          {...getFieldProps(SignInFormField.EMAIL)}
        />
      </InputWrapper>
      <InputWrapper>
        <PasswordInput
          errorMessage={errors[SignInFormField.PASSWORD]}
          labelText={SIGN_IN_FORM_TEXT.PASSWORD_LABEL}
          {...getFieldProps(SignInFormField.PASSWORD)}
        />
      </InputWrapper>
      <ForgotRememberMeWrapper>
        <StyledCheckbox
          {...getFieldProps(SignInFormField.REMEMBER)}
          checked={values[SignInFormField.REMEMBER]}
        >
          {SIGN_IN_FORM_TEXT.REMEMBER_ME}
        </StyledCheckbox>
        <StyledLink to={APP_ROUTE.forgotPassword}>
          {SIGN_IN_FORM_TEXT.FORGOT_PASSWORD}
        </StyledLink>
      </ForgotRememberMeWrapper>
      <StyledButton
        htmlType='submit'
        size='large'
        loading={isAuthenticateLoading}
      >
        {SIGN_IN_FORM_TEXT.SIGN_IN_BUTTON_TEXT}
      </StyledButton>
    </form>
  );
};

export default SignInForm;
