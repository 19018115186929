import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';
import CustomAlert from 'components/CustomAlert';
import Button from 'components/Button';

export const InputWrapper = styled('div')({
  marginBottom: '40px',
});

export const StyledSendLinkButton = styled(Button)({
  width: '100%',
  marginBottom: '20px',
  '&.ant-btn-icon-only.ant-btn-lg': {
    width: '100%',
  },
});

export const ResendButtonNotAvailableText = styled('p')({
  marginBottom: '20px',
  fontSize: '16px',
  fontWeight: 500,
  color: COLORS.gray600,
  textAlign: 'center',
  '@media (max-width: 1024px)': {
    marginBottom: '170px',
  }
});

export const ResendButtonAvailableText = styled('p')({
  marginBottom: 0,
  fontWeight: 500,
  fontSize: '18px',
  color: COLORS.gray900,
});

export const ResendButtonWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '20px',
  '@media (max-width: 1024px)': {
    marginBottom: '160px',
  }
});

export const StyledResendButton = styled(Button)({
  width: '100px!important',
  marginLeft: '10px',
});

export const StyledAlert = styled(CustomAlert)({
  marginBottom: '32px',
  whiteSpace: 'pre-line',
});
