import { useState, useEffect } from 'react';
import { To as TTo, useLocation } from 'react-router';
import { findLast, findLastIndex } from 'lodash';

import {
  EMPTY_ARRAY,
  INDEX_INCREMENT,
  NOT_FOUND_PATH_POSTFIX,
} from 'constants/common';
import {
  getSessionStorageValue,
  setSessionStorageValue,
} from 'utils/sessionStorage';
import { TBackButtonHistory } from 'types/sessionStorage';

import { IUseGoBackReturn, TLocation } from './types';

export const useGoBack = (to: TTo, allowBack = true): IUseGoBackReturn => {
  const { pathname: currentPath, state } = useLocation() as TLocation;
  const [backPath, setBackPath] = useState<TTo>(to);

  useEffect(() => {
    const backButtonHistory = getSessionStorageValue(
      'backButtonHistory',
      EMPTY_ARRAY as TBackButtonHistory[],
    );
    const previousPath = findLast(
      backButtonHistory,
      (location, index) =>
        location.pathname !== currentPath &&
        `${location.pathname.toString()}${NOT_FOUND_PATH_POSTFIX}` !==
          currentPath &&
        (!state?.routeReplaced || index < backButtonHistory.length - 1),
    );

    const backPath =
      allowBack && previousPath
        ? `${previousPath?.pathname}${previousPath?.lastSearch}`
        : to;

    setBackPath(backPath);
  }, [currentPath]);

  const handleGoBack = () => {
    const backButtonHistory = getSessionStorageValue(
      'backButtonHistory',
      EMPTY_ARRAY as TBackButtonHistory[],
    );
    const newBackButtonHistory = [...backButtonHistory];

    const lastLocation = newBackButtonHistory[newBackButtonHistory.length - 1];

    const lastDifferentLocationIndex = findLastIndex(
      newBackButtonHistory,
      (location, index) =>
        location.pathname !== lastLocation?.pathname &&
        `${location.pathname.toString()}${NOT_FOUND_PATH_POSTFIX}` !==
          currentPath &&
        (!state?.routeReplaced || index < backButtonHistory.length - 1),
    );

    if (lastDifferentLocationIndex !== -1) {
      newBackButtonHistory.splice(lastDifferentLocationIndex + INDEX_INCREMENT);
    }

    setSessionStorageValue('backButtonHistory', newBackButtonHistory);
  };

  const getBackPath = () => {
    handleGoBack();

    return backPath;
  };

  return [getBackPath, handleGoBack, backPath];
};
