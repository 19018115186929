import { EMPTY_STRING } from 'constants/common';
import { APP_ROUTE } from 'constants/appRoutes';

import { ForgotPasswordField } from './types';

export const INITIAL_FORGOT_PASSWORD_VALUES = {
  [ForgotPasswordField.email]: EMPTY_STRING,
};

export const FORGOT_PASSWORD_TEXT = {
  sendLinkButtonText: 'Send link to email',
  inputLabel: 'Email',
  resendButtonNotAvailable:
    'The Resend button will be available after 2 minutes.',
  didntReceiveEmail: "Didn't receive an email?",
  resendButtonText: 'Resend',
  linkToForgotText: 'Back to Forgot password',
  linkToSignInText: 'Back to Sign in',
  verifyPageTitle: 'Verify Your Email',
  forgotPageTitle: 'Forgot password?',
  forgotPageSubTitle: 'Enter your email and we will send you\na reset link',
  forgotPasswordAlert:
    "Password reset link could not be sent as the time interval limit of 2 minutes didn't pass yet. Please wait a bit more to resend the link.",
};

export const BACK_TO_SIGN_IN_CONFIG = {
  to: APP_ROUTE.signIn,
  title: FORGOT_PASSWORD_TEXT.linkToSignInText,
};

export const BACK_TO_FORGOT_IN_CONFIG = {
  to: APP_ROUTE.forgotPassword,
  title: FORGOT_PASSWORD_TEXT.linkToForgotText,
};

export const RESEND_LINK_TIMER_DURATION_MS = 120000;
export const INITIAL_TIMER_VALUE = 0;

export const SERVER_ERROR_STRING = 'Something went wrong. Please try again';

export const successResetLinkMessage = (email: string) => (
  <>
    We&apos;ve sent a link to your email address:
    <br />
    <span>{email}.</span>
    <br /> Please check your mailbox.
  </>
);
