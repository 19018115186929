import { useMutation, UseMutationResult } from 'react-query';

import { NOTIFICATION_SUCCESS_MESSAGE } from 'constants/messages';
import { apiClient, queryClient } from 'api/base';
import { ApiRoutes } from 'api/routes';
import { openNotificationWithIcon } from 'utils/showNotification';
import { NotificationType } from 'types/notifications';
import { QUERY_KEYS } from 'constants/queryKeys';
import { TAxiosRequestError } from 'api/types';

import { IUseUpdateProfilePayload, IUseUpdateProfileResponse } from './types';

const useUpdateUserProfile = (
  onSuccess?: () => void,
  onError?: (error: TAxiosRequestError) => void,
): UseMutationResult<
  IUseUpdateProfileResponse,
  TAxiosRequestError,
  IUseUpdateProfilePayload
> => {
  const handleUpdateSuccess: (
    response: IUseUpdateProfileResponse,
    payload: IUseUpdateProfilePayload,
  ) => void = (response, payload) => {
    const isPasswordChanged = payload.newPassword && payload.oldPassword;
    let successMessage = NOTIFICATION_SUCCESS_MESSAGE.updateProfileDetails;

    if (isPasswordChanged && response) {
      successMessage = response?.sendingEmailErrorMessage
        ? NOTIFICATION_SUCCESS_MESSAGE.updateProfileDetailsPasswordWithSendingEmailError
        : NOTIFICATION_SUCCESS_MESSAGE.updateProfileDetailsPassword;
    }

    if (onSuccess) {
      onSuccess();
    }

    openNotificationWithIcon(NotificationType.success, successMessage);
    queryClient.invalidateQueries([QUERY_KEYS.USER_PROFILE]);
  };

  return useMutation(
    async (profilePayload: IUseUpdateProfilePayload) => {
      const { data: dataResponse } = await apiClient.post(
        ApiRoutes.UPDATE_USER_PROFILE,
        profilePayload,
      );

      return dataResponse;
    },
    {
      retry: false,
      onSuccess: handleUpdateSuccess,
      onError,
    },
  );
};

export default useUpdateUserProfile;
