import styled from 'styled-components';
import { Checkbox } from 'antd';
import { Link } from 'react-router-dom';

import { COLORS } from 'constants/colorPalette';
import Button from 'components/Button';
import CustomAlert from 'components/CustomAlert';

export const StyledAlert = styled(CustomAlert)({
  marginBottom: '32px',
});

export const InputWrapper = styled('div')({
  marginBottom: '20px',
});

export const ForgotRememberMeWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '64px',
});

export const StyledCheckbox = styled(Checkbox)({
  fontWeight: 500,
  lineHeight: 1.4,
  color: COLORS.gray900,
});

export const StyledLink = styled(Link)({
  fontSize: '14px',
  fontWeight: 500,
  color: COLORS.blue500,
});

export const StyledButton = styled(Button)({
  width: '100%',
  marginBottom: '20px',
  '&.ant-btn-icon-only.ant-btn-lg': {
    width: '100%',
  },
  '@media (max-width: 1024px)': {
    marginBottom: '16px',
  }
});
