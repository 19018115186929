import { FC } from 'react';
import { isEmpty } from 'lodash';

import { DefaultInput, MAX_EMAIL_LENGTH } from 'components/Inputs';
import HelmetPageTitle from 'components/HelmetPageTitle';
import { ALERT_TYPE } from 'components/Alert';
import { ContentCard } from 'components/UnauthorizedPages';
import { INITIAL_TIMER_VALUE } from 'constants/common';
import { MENU_TITLE } from 'constants/menu';
import { SERVER_ERROR_STRING } from 'constants/messages';

import {
  FORGOT_PASSWORD_TEXT,
  BACK_TO_SIGN_IN_CONFIG,
  BACK_TO_FORGOT_IN_CONFIG,
  successResetLinkMessage,
} from './constants';
import { ForgotPasswordField, IForgotPasswordProps } from './types';
import {
  InputWrapper,
  StyledSendLinkButton,
  StyledAlert,
  ResendButtonNotAvailableText,
  ResendButtonWrapper,
  ResendButtonAvailableText,
  StyledResendButton,
} from './styled';

const ForgotPassword: FC<IForgotPasswordProps> = ({
  formik,
  timer,
  errorMessage,
  isFormVisible,
  isSendLinkLoading,
  onBackLinkClick,
  onResendButtonClick,
}) => {
  const { values, errors, handleSubmit, getFieldProps } = formik;

  const isResetLinkErrorMessage =
    errorMessage && errorMessage.includes(SERVER_ERROR_STRING);
  const isErrorAlertShown = errorMessage && isEmpty(errors);

  return (
    <HelmetPageTitle title={MENU_TITLE.forgotPassword}>
      {isFormVisible ? (
        <ContentCard
          title={FORGOT_PASSWORD_TEXT.forgotPageTitle}
          subTitle={FORGOT_PASSWORD_TEXT.forgotPageSubTitle}
          backLinkConfig={BACK_TO_SIGN_IN_CONFIG}
        >
          {isErrorAlertShown && !isResetLinkErrorMessage && (
            <StyledAlert type={ALERT_TYPE.ERROR} message={errorMessage} />
          )}
          <form onSubmit={handleSubmit}>
            <InputWrapper>
              <DefaultInput
                labelText={FORGOT_PASSWORD_TEXT.inputLabel}
                errorMessage={errors[ForgotPasswordField.email]}
                maxLength={MAX_EMAIL_LENGTH}
                {...getFieldProps(ForgotPasswordField.email)}
              />
            </InputWrapper>
            <StyledSendLinkButton
              htmlType='submit'
              size='large'
              loading={isSendLinkLoading}
            >
              {FORGOT_PASSWORD_TEXT.sendLinkButtonText}
            </StyledSendLinkButton>
          </form>
        </ContentCard>
      ) : (
        <ContentCard
          title={FORGOT_PASSWORD_TEXT.verifyPageTitle}
          backLinkConfig={{
            ...BACK_TO_FORGOT_IN_CONFIG,
            onClick: onBackLinkClick,
          }}
        >
          <StyledAlert
            type={
              isResetLinkErrorMessage ? ALERT_TYPE.ERROR : ALERT_TYPE.SUCCESS
            }
            message={
              isResetLinkErrorMessage
                ? errorMessage
                : successResetLinkMessage(values[ForgotPasswordField.email])
            }
          />
          {timer !== INITIAL_TIMER_VALUE ? (
            <ResendButtonNotAvailableText>
              {FORGOT_PASSWORD_TEXT.resendButtonNotAvailable}
            </ResendButtonNotAvailableText>
          ) : (
            <ResendButtonWrapper>
              <ResendButtonAvailableText>
                {FORGOT_PASSWORD_TEXT.didntReceiveEmail}
              </ResendButtonAvailableText>
              <StyledResendButton
                htmlType='submit'
                type='default'
                loading={isSendLinkLoading}
                onClick={onResendButtonClick}
              >
                {FORGOT_PASSWORD_TEXT.resendButtonText}
              </StyledResendButton>
            </ResendButtonWrapper>
          )}
        </ContentCard>
      )}
    </HelmetPageTitle>
  );
};

export default ForgotPassword;
